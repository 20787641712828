<template>
  <div class="blog-wrapper">
    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card>
            <div class="blog-content">
              <h1>{{ $options.filters.transl(item, 'title') }}</h1>
              <div v-html="$options.filters.transl(item, 'description')" />
            </div>
          </b-card>
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->
  </div>
</template>

<script>
import GlobalMixin from '@mixins/GlobalMixin'
import router from '@/router'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
    }
  },
  watch: {
    '$route.params': {
      handler() {
        this.fetchPage()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async fetchPage() {
      await this.$http.get(`/api/promo/pages/${router.currentRoute.params.slug}`)
        .then(response => {
          const { data } = response.data
          this.item = data

          // document.title = `${this.$options.filters.transl(this.item, 'title')} - TeLeAds`
          // document.body.querySelector('.content-header-title').innerHTML = this.$options.filters.transl(this.item, 'title')
          // document.body.querySelector('.breadcrumb-wrapper').style = 'display: none;'
          // document.head.querySelector('meta[name=description]').content = this.$options.filters.transl(this.item, 'meta_description')
        })
        .catch(error => {
          if (error.response.status === 404) {
            router.replace({ name: 'error-404' })
          }
        })
    },
  },
}
</script>

<style lang="scss">

.page_wrap {
  .content-header {
    display: none;
  }

  .blog-content {
    h1 {
      display: block;
      text-align: center;
      margin:0 0 30px;
    }
  }
}
</style>
